<template>
	<router-view/>
</template>

<script>
import FontAwesomeDuoTone from './assets/js/duotone.js'
import FontAwesome from './assets/js/fontawesome.js'
export default {}
</script>

<style>
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/

html{color:#000;background:#FFF}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,em,strong,th,var{font-style:normal;font-weight:normal}ol,ul{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit;*font-size:100%}legend{color:#000}#yui3-css-stamp.cssreset{display:none}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
	box-sizing: border-box;
	font-size: 62.5%;
}

*, *:before, *:after {
	box-sizing: inherit;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	font-size: 1.5rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}



button,
.button {
	background-color: transparent;
}

button,
.button {
	border: 2px solid #1cb2ca;
}

.field-group span {
	color: #303030;
}

button,
.button {
	color: #1cb2ca;
}

h1,
fieldset legend {
	color: #051b35;
}

label,
button,
.button {
	cursor: pointer;
}

label {
	display: block;
}

.field-group {
	display: flex;
}

h1 {
	font-size: 2.7rem;
}

fieldset legend {
	font-size: 2.1rem;
}

.field-group span {
	font-size: 1.8rem;
}

button,
.button {
	font-weight: bold;
}

.field-group span,
.files {
	line-height: 2.3;
}

fieldset legend {
	margin-bottom: 12px;
}

form {
	margin: 0 auto;
}

h1,
fieldset + fieldset legend,
.submit-group {
	padding-top: 2rem;;
}

.field-group span {
	padding: 0 3px;
}

h1 {
	text-align: center;
}

h1,
button,
.button {
	text-transform: uppercase;
}

input,
textarea {
	width: 100%;
}


/* forms */
form {
	margin-bottom: 1.5rem;
	max-width: 428px;
	padding: 12px;
	width: 100vw;
}

.label-field {
	margin-bottom: 15px;
}

	label {
		margin-bottom: 6px;
	}

	input,
	textarea,
	select {
		padding: 0 6px;
	}

		textarea {
			padding: 6px;
		}

	button {
		padding: 0 30px;
	}

	input,
	button {
		min-width: 46px;
		min-height: 42px;
	}

	input.inp-sm {
		width: auto;
	}

	.submit-group {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.agree {
		font-size: 1.3rem;
		margin-bottom: 15px;
	}

		button:disabled {
			background-color: #f5f5f5;
			color: #1cb2ca;
		}


nav + div,
.add-nav + div,
.go-back + div {
	margin-top: 60px;
}

nav,
.add-nav,
.go-back {
	background-color: rgba(255, 255, 255, .6);
	backdrop-filter: blur(5px);
	border-bottom: 1px solid #1cb2ca;
	padding: 9px 15px;
	position: fixed;
	top: 0;
	width: 100%;
}

.sub-nav,
.go-back {
	padding: 15px;
}

	.sub-nav a,
	.go-back a {
		color: #1cb2ca;
		cursor: pointer;
		font-size: 1.8rem;
		text-decoration: none;
	}

.go-back {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

/* mentor / mentee list */
.filter-sort {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding: 9px;
}

	.filter-sort input,
	.filter-sort button {
		margin: 0 9px;
		max-width: 180px;
	}

		.filter-sort button.disabled {
			opacity: .57;
		}

		.filter-sort span {
			display: inline-block;
		}

		@media (max-width:767px){
			.filter-sort {
				flex-direction: column;
			}

			.filter-sort button,
			.filter-sort span {
				margin-top: 6px;
				margin-bottom: 6px;
			}
		}

	.m-list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}

		.m-list::after {
			content: "";
			flex: auto;
		}

		.m-list > div {
			border: 1px solid #051b35;
			margin: 12px;
			width: calc( 25vw - 40px );
			height: calc( 25vw - 40px );
		}

			@media screen and (max-width: 767px) {
				.m-list > div {
					width: calc( 50% - 24px );
					min-height: 210px;
				}
			}

			.m-bg {
				background-color: rgba(255, 255, 255,.75);
			}

			.m-list .mentor > div,
			.m-list .mentee > div {
				color: #000;
				display: flex;
				flex-direction: column;
				font-size: 1.62rem;
				height: 100%;
				justify-content: space-between;
				text-decoration: none;
			}

				@media screen and (max-width: 767px) {
					.m-list .mentor > div,
					.m-list .mentee > div {
						font-size: 1.5rem;
					}
				}

				.m-list .m-name {
					background-color: rgba(255, 255, 255, .87);
					border-bottom: 1px solid #000;
					padding: 6px
				}

				.m-data {
					border-top: 1px solid #000;
					display: flex;
					background-color: rgba(255,255,255,.72);
					padding: 9px 6px;
				}

					.m-data .contracts {
						color: #1cb2ca;
						margin-left: auto;
					}

						.m-data li {
							float: left;
							margin-right: 9px;
						}

				.m-list .mentor a,
				.m-list .mentee a {
					margin: 6px;
					padding: 6px;
					text-align: center;
					text-decoration: none;
				}

				.m-list .button {
					background-color: rgba(255, 255, 255, .81);
				}

				.m-utility {
					justify-content: flex-end;
					display: flex;
					height: auto;
					margin-top: auto;
					padding-bottom: 6px;
				}

					.m-list .m-utility a {
						padding: 6px 12px;
					}



.uploadcare--widget_status_ready + .uploadcare--widget_status_ready {
	display: none;
}



.all-files > li {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}

	.all-files .file-utility {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

		.all-files .file-utility li {
			margin: 6px 0;
		}

		.all-files button,
		.all-files .button {
			display: block;
			min-height: 15px;
			padding: 12px 15px;
			width: 100%;
		}

			.all-files .button {
				text-decoration: none;
			}

			.file-u {
				border-bottom: 1px solid #051b35;
				margin-bottom: 30px;
				padding-bottom: 30px;
			}

fieldset li {
	margin-bottom: 6px;
}

.checkbox-group,
.radio-group {
	align-items: center;
	display: flex;
	margin: 3px 0;
	padding: 3px 0;
}

	.checkbox-group input,
	.radio-group input {
		margin-right: 6px;
		width: auto;
		min-width: 12px;
		min-height: 12px;
	}

	.checkbox-group label,
	.radio-group label {
		margin: 0;
	}

/* view */
.view-profile ul.main {
	font-size: 1.8rem;
	margin: 20px auto;
	padding-right: 12px;
	padding-left: 12px;
	max-width: 600px;
}

	.view-profile ul.main > li {
		margin-bottom: 12px;
	}

	.view-profile ul.files {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding-top: 12px;
	}

	.view-profile ul.main a {
		color: #1cb2ca;
		text-decoration: none;
	}
</style>
