<template>
	<div class="admin">
		<NavBar />
		<div id="dashboard">
			<h1>Admin</h1>
			<ul>
				<li>
					<router-link :to="{ name: 'Mentors'}">
						<i class="fad fa-chalkboard-teacher fa-lg"></i>
						<span>Mentors</span>
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'Mentees'}">
						<i class="fad fa-user-graduate fa-lg"></i>
						<span>Mentees</span>
					</router-link>
				</li>
			</ul>
			<p class="ver">v0.3.8</p>
		</div>
	</div>
</template>

<script>
import NavBar from '../components/NavBar.vue'

export default {
	name: 'Admin',
	components: { NavBar },
}
</script>
<style scoped>
#dashboard {
	padding: 15px;
}

	#dashboard ul {
		display: flex;
		justify-content: space-around;
		margin-top: 30px;
	}

	#dashboard ul a {
		color: #1cb2ca;
		font-size: 2.4rem;
		text-align: center;
		text-decoration: none;
	}

		#dashboard ul a svg {
			display: block;
			margin: 0 auto;
		}

		#dashboard ul a span {
			display: block;
		}

.ver {
	font-size: 1.1rem;
	padding: 6px 0;
}
</style>
