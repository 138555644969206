<template>
	<div class="filter-sort">
		<input placeholder="search last name (all)" type="text" v-model="searchTerm" /> 
		<span>OR</span>
		<button v-on:click="filterRecs('spring21')" v-bind:class="{'disabled' : spring21 == false }">Spring 21</button>
		<button v-on:click="filterRecs('fall21')" v-bind:class="{'disabled' : fall21 == false }">Fall 21</button>
		<button v-on:click="filterRecs('spring22')" v-bind:class="{'disabled' : spring22 == false }">Spring 22</button>
		<button v-on:click="filterRecs('fall22')" v-bind:class="{'disabled' : fall22 == false }">Fall 22</button>

		<button v-on:click="filterRecs('spring23')" v-bind:class="{'disabled' : spring23 == false }">Spring 23</button>
		<button v-on:click="filterRecs('fall23')" v-bind:class="{'disabled' : fall23 == false }">Fall 23</button>

		<button v-on:click="filterRecs('spring24')" v-bind:class="{'disabled' : spring24 == false }">Spring 24</button>
		<button v-on:click="filterRecs('fall24')" v-bind:class="{'disabled' : fall24 == false }">Fall 24</button>

		<button v-on:click="filterRecs('spring25')" v-bind:class="{'disabled' : spring25 == false }">Spring 25</button>
		<button v-on:click="filterRecs('fall25')" v-bind:class="{'disabled' : fall25 == false }">Fall 25</button>
	</div>
	<div class="m-list">
		<div v-for="mentor in mentorsFiltered" :key="mentor.id" class="mentor" :style="mentor.headshots && mentor.headshots[0] ? 'background-image:url(https://ucarecdn.com/' + mentor.headshots[0] + '/-/stretch/fill/-/resize/210x/-/quality/lightest/-/blur/66)' + '; background-size: cover; background-position: center center': false">
			<div class="m-bg">
				<SingleMentor :mentor="mentor" />
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, onUnmounted, onUpdated } from 'vue'

// component imports
import SingleMentor from './SingleMentor.vue'

export default {
	data(){
		return {
			searchTerm: '',
			spring21: false,
			fall21: false,
			spring22: false,
			fall22: false,
			spring23: false,
			fall23: false,
			spring24: false,
			fall24: false,
			spring25: true,
			fall25: false
		}
	},
	props: ['mentors'],
	components: { SingleMentor },
	computed: {
		mentorsFiltered(){



			let filterGroup = '2025-Spring'

			if(this.spring21 === true){
				filterGroup = '2021-Spring'
			} else if(this.fall21 === true) {
				filterGroup = '2021-Fall'
			} else if(this.spring22 === true) {
				filterGroup = '2022-Spring'
			} else if(this.fall22 === true) {
				filterGroup = '2022-Fall'
			} else if(this.spring23 === true) {
				filterGroup = '2023-Spring'
			} else if(this.fall23 === true) {
				filterGroup = '2023-Fall'
			} else if(this.spring24 === true) {
				filterGroup = '2024-Spring'
			} else if(this.fall24 === true) {
				filterGroup = '2024-Fall'
			} else if(this.spring25 === true) {
				filterGroup = '2025-Spring'
			} else if(this.fall25 === true) {
				filterGroup = '2025-Fall'
			}

			if(this.searchTerm != ''){
				return this.mentors.filter(mentor => {
					return mentor['name-last'].toLowerCase().match(this.searchTerm.toLowerCase())
				})
			} else {
				return this.mentors.filter(mentor => {
					console.log('--')
					console.log(mentor)
					console.log('--')
					return mentor['sessions'].includes(filterGroup) || mentor['sessions'].length < 1
				})
			}



		}	
	},
	methods: {
		filterRecs(which){
			if(which === 'spring21'){
				this.spring21 = true
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'fall21'){ 
				this.spring21 = false
				this.fall21 = true
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'spring22'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = true
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'fall22'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = true
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'spring23'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = true
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'fall23'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = true
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'spring24'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = true
				this.fall24 = false
				this.spring25 = false
				this.fall25 = false
			} else if(which === 'fall24'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = true
				this.spring25 = false
				this.fall25 = false
			}  else if(which === 'spring25'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = true
				this.fall25 = false
			} else if(which === 'fall25'){ 
				this.spring21 = false
				this.fall21 = false
				this.spring22 = false
				this.fall22 = false
				this.spring23 = false
				this.fall23 = false
				this.spring24 = false
				this.fall24 = false
				this.spring25 = false
				this.fall25 = true
			}
		}
	}
}
</script>
